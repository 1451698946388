import React from 'react';

const QuestionCard = ({text, sn,cl}) => {

    //console.log({text, sn,cl});
    return (
        <div className='mb-2' >
            <p className='text-slate-700 text-xl md:2xl text-center '> {text}</p>
        </div>
    );
}

export default QuestionCard