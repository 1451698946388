import { configureStore } from '@reduxjs/toolkit';
import branchListReducer from '../features/branch/branchSlice';
import adminAuthReducer from '../features/authentication/authSlice';
import evaluatorDataReducer from '../features/summary/summarySlice';
import assessmentSliceReducer from '../features/assessment/assessmentSlice';

export const store = configureStore({
  reducer: {
    auth: adminAuthReducer,
    branches: branchListReducer,
    evaluatorData: evaluatorDataReducer,
    assessment: assessmentSliceReducer,
  },
  devTools: true,
});
