import React from 'react';
import EvaluatorDashboard from '../pages/EvaluatorDashboard';
import { Routes, Route } from 'react-router-dom';
import SigninPage from '../features/authentication/Signin';
import { Toaster } from 'react-hot-toast';
import EvaluatorSummaryForm from '../features/assessment/EvaluatorForm/EvaluatorSummaryForm';


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<SigninPage />} />
        <Route path="/form" element={<EvaluatorSummaryForm />} />
        <Route exact path="/evaluator"  element={<EvaluatorDashboard />} />
      </Routes>

      <Toaster />
    </>
  );
}

export default App;
