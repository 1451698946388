/* eslint-disable max-len */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAssessmentQuestions, submitAssessment } from './assessmentAPI';

const initialState = {};
const reducers = {};

export const fetchQuestionsThunk = createAsyncThunk('assessment/fetchQuestions', async ({assessmentQuestionsID, branchID}, { getState }) => {
  const { token } = getState().auth;
  const payload = await fetchAssessmentQuestions(assessmentQuestionsID, token);
  //console.log({ questions: payload, branch: branchID, assessmentQuestionsID });

  return { questions: payload, branchID, assessmentQuestionsID }; //this is the payload
});

export const submitAssessmentThunk = createAsyncThunk('assessment/submitAssessment', async (assessment, { getState }) => {
    const { token } = getState().auth;
    const payload = await submitAssessment(assessment, token);
    return { result: payload }; //this is the payload
});



const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder
        .addCase(fetchQuestionsThunk.fulfilled, (state, action) => ({ ...action.payload }))
  },
});

export default assessmentSlice.reducer;
