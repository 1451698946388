import axios from "axios";
import toast from "react-hot-toast";

const baseURL = 'https://mysteryshopper.connectifie.com/api/v1.0';


export const getEvaluatorData = async ({access_code, makeToast=false, campaignID = 3, token}) => {
    try{
        if (makeToast) {
            toast.remove(); 
            toast.loading('Loading Campaign...');
        }

        const { data: evaluatorSummary } = await axios.get(`${baseURL}/evaluator/report/${access_code}?campaign_id=${campaignID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        //console.log({ evaluatorPayment2: evaluatorSummary })

        const { data: evaluatorPayment } = await axios.get(`${baseURL}/payment/evaluator/${access_code}?campaign_id=${campaignID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        //console.log({ evaluatorPayment })

        const { data: branchResponse } = await axios.get(`${baseURL}/branch-list?campaign_id=2`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const branches = branchResponse.map(branch=>{
            return {
                name: branch.branch_name,
                address: branch.address,
                code: branch.branch_code,
                bank: branch.bank_name,
                state: branch.state,
                zone: branch.zone,
                group: branch.group_id,
            }
        })

        const { data: evaluatorTasks } = await axios.get(`${baseURL}/campaign/assessment/scope/evaluator/${access_code}?campaign_id=${campaignID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const formattedTasks = evaluatorTasks.map(task=> {
            const branchInfo = branches.find(branch=> branch.code == task.branch_code || branch.code == task.branch_id );

            return { ...task, ...branchInfo }
        })

        console.log({ campaignID, evaluatorTasks })
    
        const formatMoney = (money) => {
            return money? money.split('.')[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
        }
    
        //console.log({ ...evaluatorPayment, ...evaluatorSummary, ... campaignID, wages: formatMoney(evaluatorPayment.wages), allTaskAssign: evaluatorTasks })
        if (makeToast){ 
            toast.remove(); 
            toast.success('Campaign loaded Successfully');
        }
        
        return { ...evaluatorPayment, ...evaluatorSummary, campaignID, wages: formatMoney(evaluatorPayment.wages), allTaskAssign: formattedTasks };
    } catch(error){
        console.log({evalData: error.response});
    }
}

export const getEvaluatorTasks = async (access_code, campaignID, token) => {

    const { data: evaluatorTasks } = await axios.get(`${baseURL}/campaign/assessment/scope/evaluator/${access_code}?campaign_id=${campaignID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    return { allTaskAssign: evaluatorTasks };
}

export const getCampaignTypes = async (campaignID, token) => {
    try{
        const { data } = await axios.get(`${baseURL}/assessment/by-campaign/${campaignID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        console.log(data);
        return { campaignTypes: data};
    } catch(e){
        console.log(e.message)
    }
}

export const sendFeedback = async (formData, token) => {
    try{
        const { data } = await axios.post(`${baseURL}/evaluator/feedback`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        toast.remove();
        data.success? toast.success(data.msg) : toast.error(data.msg);
        return data;
    } catch(e){
        console.log(e.message)
    }
}