// Donut chart
import ReactApexChart from 'react-apexcharts';

const DonutChart = ({data, titleText, className}) => {
	const state = {
		series: data,
		options: {
			chart: {
				type: 'donut',
				animation: {
					enabled: "true",
					easing: "easeinout",
					speed: 800,
					animateGradually: {
						enabled: true,
					},
					dynamicAnimation: {
						enabled: true,
						speed: 350
					}
				}
			},
			plotOptions: {
				customScale: 0.8,
				pie: {
					donut: {
						size: '90%',
						labels: {
							show: true,
							total: {
								show: true,
								showAlways: true
							}
						}
					},
					expandOnClick: false,
				}
			},
			stroke: {
				width: 0.1,
				color: ['#fff']
			},
			dataLabels: {
				enabled: false,
				textAnchor: 'middle',
				style: {
					colors: ['white'],
					fontSize: '14px',

				},
				background: {
					enabled: true,
					foreColor: 'slate',
					opacity: 0.7,
				}
			},
			labels: ['Completed', 'Not Completed'],
			colors: ['#e58b34', '#e1e2e3'],
			legend: {
				position: 'bottom',
			},			
			title: {
				text: titleText,
				floating: false,
				style: {
					color: 'gray',
				}
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: '100%',
							height: 300,
						},			
						colors: ['#e58b34', '#e1e2e3'],
						dataLabels: {
							enabled: false,
						},
						legend: {
							position: 'bottom',
						},
						plotOptions: {
							customScale: 0.8,
							pie: {
								donut: {
									size: '90%',
									labels: {
										show: true,
										total: {
											show: true,
											showAlways: true
										}
									}
								},
								expandOnClick: false,
							}
						},
					},
				},
			],
		},
	};

	return (
		<div id="chart" className={className? className : ''}>
			<ReactApexChart options={state.options} series={state.series} type="donut" />
		</div>
	);
};

export default DonutChart;