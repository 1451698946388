import React from 'react'
import ProgressBar from '../../../components/charts/ProgressBar';
import { useNavigate} from 'react-router-dom'
import {useState, useEffect} from 'react'
import FormStep from './FormStep';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuestionsThunk, submitAssessmentThunk } from '../assessmentSlice';
import toast from 'react-hot-toast';


const EvaluatorSummaryForm = ({ assessmentTypeID }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { questions, branchID, assessmentQuestionsID } = useSelector(state => state.assessment);
    const { campaignID } = useSelector(state=> state.evaluatorData);

    //const [loading, setLoading] = useState(false);
    const [data, setData] = useState(questions? questions : []);
	const [totalSteps, setTotalSteps] = useState(0);
	const [currentStep, setCurrentStep] = useState(0);
    const [Questions, setQuestions] = useState([]);
	const [allAnswers, setAllAnswers] = useState([]);
    const [showPreQuestions, setShowPreQuestions] = useState(true);
    const [isPreQuestionAnswered, setIsPreQuestionAnswered] = useState(false);

    useEffect(() => {
        if(questions) setData(questions)
        else { navigate('/evaluator') }
    },[navigate, questions]);


    useEffect(() =>{
        if (data.length){
            //console.log(data.length, data)
            let questionsArray = [...data];
            let questionsSubArr = [];
            while(questionsArray.length) {
                const newArr = questionsArray.splice(0,1);
                questionsSubArr.push(newArr);
            }
            let insertIndex = 1;
            for (let i = 0; i < data.length; i++) {
                questionsSubArr.splice(insertIndex, 0, [
                    {
                        ...data[i],
                        type: 'radio',
                    },
                ]);
                insertIndex += 2;
            }

            questionsSubArr = questionsSubArr.filter(arr=> arr[0].type);

            setQuestions(questionsSubArr);
            setTotalSteps(questionsSubArr.length);
            console.log({questions}, questionsSubArr.length, questionsSubArr.filter(arr=> arr[0].type));
        }
    },[data, questions])

    //console.log({allAnswers})

    

    const [employeeAssessed, setEmployeeAssessed] = useState({});
    const handleEmployeeFormChange= (e) => {
        const date= new Date();
        const day= date.getDate() < 10? '0'+date.getDate() : date.getDate();
        const month= date.getMonth()+1 < 10? '0'+(date.getMonth()+1) : date.getMonth()+1;
        const year= date.getFullYear();

        const hour= date.getHours() < 10? '0'+date.getHours() : date.getHours();
        const minute= date.getMinutes() < 10? '0'+date.getMinutes() : date.getMinutes();
        const second= date.getSeconds() < 10? '0'+date.getSeconds() : date.getSeconds();
        const millisecond= date.getMilliseconds() < 10? '0'+date.getMilliseconds() : date.getMilliseconds();

        const timestamp= `${year}-${month}-${day} ${hour}:${minute}:${second}.${millisecond}`;

        console.log(timestamp)
        setEmployeeAssessed(prev=> ({...prev, assigned_date: timestamp,  [e.target.name]: e.target.value}));
        console.log({employeeAssessed})
    }

    const handleAnswersSubmit = () => {
        // run function to submit here
        console.log({ campaignBeingAssessedIn: campaignID })
        const requestBody = {
            evaluator_access_code: sessionStorage.getItem('evaluator_access_code'),
            assessment_type_id: assessmentQuestionsID,
            branch_id: branchID,
            allQuestions: allAnswers,
            campaign_id: campaignID, // will be passed from the state
            ...employeeAssessed,
        }
        console.log({requestBody, allAnswers})
        dispatch(submitAssessmentThunk(requestBody))
        .then(res=> {
            if (res.meta.requestStatus === 'fulfilled') {
                navigate('/evaluator');
            }else{
                toast.error("Please check network and try again")
            }
        });
    };

    useEffect(()=>{
        if (currentStep === questions.length){
            console.log({currentStep, totalSteps});
            handleAnswersSubmit();
        }
    }, [currentStep]);

    const goForward = () => {
        const date = new Date();
        const hourOfDay = date.getHours();
        if (hourOfDay < 8 || hourOfDay > 16){
            toast.error('Evaluation is out of time range for the day');
            return navigate('/evaluator');
        };
        
        setCurrentStep((prevStep) =>{
            if (prevStep + 1 === questions.length) document.getElementById('singleSubmit').disabled = true;
            return prevStep + 1;
        });
    };

    const updateFormData = (data) => {
        const newAnswers = data;
        setAllAnswers((prevState) => [...prevState, ...newAnswers]);
        goForward();
    };
    
    const assessmentTitle= assessmentQuestionsID === 4? ' Intern Assessment' : assessmentQuestionsID === 1? ' FLE Assessment' : assessmentQuestionsID === 2? ' Security Assessment' : assessmentQuestionsID === 3? 'Branch Assessment' : 'Assessment';

    return ( 
        <div className='flex flex-col overflow-y-auto items-center w-full h-screen'>
            <h1 className='font-bold text-xl text-white bg-sideBarBlue p-4 w-full h-12 text-center '>{assessmentTitle} Form</h1>
            <div className='w-full h-min px-2 py-2'>
                <ProgressBar className='w-full ' now={currentStep} max={totalSteps} label={`${currentStep} / ${totalSteps}`} progressPercentage={Math.round((currentStep/totalSteps) * 100)} />
            </div>
            <div className={`${showPreQuestions? 'hidden' : 'visible'} w-full h-full p-2`}>
                {Questions.map((questions, index) => (
                    <FormStep
                        key={`form-${index}`}
                        questions={questions}
                        step={index}
                        currentStep={currentStep}
                        updateFormData={updateFormData}
                        showPreQuestions={showPreQuestions}
                        finalStep={totalSteps - 1}
                    />
                ))}
            </div>

            <section className={`fixed h-screen w-screen bg-black opacity-50 ${showPreQuestions? 'visible' : 'hidden'}`}>
                {/**Section to show blurred background */}
            </section>
            <div className={`absolute z-10 top-32 w-full h-max min-h-max p-3 border text-lg text-slate-700 rounded-lg bg-slate-100 ${showPreQuestions? 'visible' : 'hidden'} `}>
                <h1 className='font-bold text-blue-900'>Evaluation Details</h1>
                <form className='overflow-y-auto h-max max-h-max' onChange={handleEmployeeFormChange} >
                    <label className='w-full flex flex-col text-slate-600 my-2'>
                        Time of Arrival
                        <input required type='time' value={employeeAssessed.time} className='rounded my-2 px-2 border border-slate-300  focus:outline focus:outline-accessOrange py-2' name='arrival_time' />
                    </label>
                    {
                        assessmentQuestionsID && assessmentQuestionsID == '1'? (
                            <>
                            <label className='w-full flex flex-col text-slate-600 my-2'>
                                First name of {assessmentTitle.split(' ')[1]}
                                <input required type='text' 
                                    value={employeeAssessed.employee_first_name} 
                                    className='rounded my-2 px-2 border border-slate-300  focus:outline focus:outline-accessOrange py-2' 
                                    name='employee_first_name' 
                                    placeholder={`Enter ${assessmentTitle.split(" ")[1]} First name`} 
                                />
                            </label>

                            <label className='w-full flex flex-col text-slate-600 my-2'>
                                Last name of {assessmentTitle.split(" ")[1]}
                                <input required 
                                    type='text' 
                                    value={employeeAssessed.employee_last_name} 
                                    className='rounded  my-2 px-2 border border-slate-300 focus:outline focus:outline-accessOrange py-2' 
                                    name='employee_last_name' 
                                    placeholder={`Enter ${assessmentTitle.split(" ")[1]} Last name`} 
                                />
                            </label>

                            <label className='w-full flex flex-col text-slate-600 my-2'>
                                Gender of {assessmentTitle.split(" ")[1]}
                                <select name='gender' 
                                    defaultValue={"DEFAULT"} 
                                    required 
                                    className='rounded  my-2 px-2 border border-slate-300 focus:outline focus:outline-accessOrange py-2'
                                >
                                    <option value={"DEFAULT"} disabled>Choose Gender</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </label>

                            <label className='w-full flex flex-col text-slate-600 my-2'>
                                Role of Staff
                                <select name='employee_role' 
                                    defaultValue={"DEFAULT"} 
                                    required 
                                    className='rounded  my-2 px-2 border border-slate-300 focus:outline focus:outline-accessOrange py-2'
                                >
                                    <option value={"DEFAULT"} disabled>Employee Role</option>
                                    <option value={"customer care operative"}>Customer Care Operative</option>
                                    <option value={"teller"}>Teller</option>
                                    <option value={"others"}>Others</option>
                                </select>
                            </label>
                            </>
                        ) : assessmentQuestionsID && assessmentQuestionsID == '2' ? (

                        <>
                            <label className='w-full flex flex-col text-slate-600 my-2'>
                                First name of {assessmentTitle.split(' ')[1]}
                                <input required type='text' 
                                    value={employeeAssessed.employee_first_name} 
                                    className='rounded my-2 px-2 border border-slate-300  focus:outline focus:outline-accessOrange py-2' 
                                    name='employee_first_name' 
                                    placeholder={`Enter ${assessmentTitle.split(" ")[1]} First name`} 
                                />
                            </label>

                            <label className='w-full flex flex-col text-slate-600 my-2'>
                                Last name of {assessmentTitle.split(" ")[1]}
                                <input required type='text' 
                                    value={employeeAssessed.employee_last_name} 
                                    className='rounded  my-2 px-2 border border-slate-300 focus:outline focus:outline-accessOrange py-2' 
                                    name='employee_last_name' 
                                    placeholder={`Enter ${assessmentTitle.split(" ")[1]} Last name`} 
                                />
                            </label>

                            <label className='w-full flex flex-col text-slate-600 my-2'>
                                Gender of {assessmentTitle.split(" ")[1]}
                                <select name='gender' defaultValue={"DEFAULT"} required className='rounded  my-2 px-2 border border-slate-300 focus:outline focus:outline-accessOrange py-2'>
                                    <option value={"DEFAULT"} disabled>Choose Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </label>
                        </>
                        ) : null
                    }
                    

                    <button 
                        onClick={(e)=>{ 
                            e.preventDefault(); 
                            if( (
                                    employeeAssessed.employee_first_name?.length && employeeAssessed.employee_last_name?.length && 
                                    employeeAssessed.employee_role?.length && employeeAssessed.gender?.length && employeeAssessed.arrival_time?.length
                                )  
                                || 
                                (
                                    assessmentQuestionsID == 2 && 
                                    employeeAssessed.employee_first_name?.length && 
                                    employeeAssessed.employee_last_name?.length && 
                                    employeeAssessed.gender?.length && employeeAssessed.arrival_time?.length
                                )  
                                || 
                                (assessmentQuestionsID == '3' && employeeAssessed.arrival_time?.length)
                            ) {
                                if(
                                
                                    ((assessmentQuestionsID != '3' && `${branchID[0]}${branchID[1]}${branchID[2]}` == 'ACB' && ['na', 'ni', 'none', 'non', 'nil', 'n/a', 'n/l', 'nill'].includes(employeeAssessed.employee_first_name.toLowerCase()))
                                && 
                                    (assessmentQuestionsID != '3' && `${branchID[0]}${branchID[1]}${branchID[2]}` == 'ACB' && ['na', 'ni', 'none', 'non', 'nil', 'n/a', 'n/l', 'nill'].includes(employeeAssessed.employee_last_name.toLowerCase())))
                                || 
                                    (assessmentQuestionsID != '3' && (`${branchID[0]}${branchID[1]}${branchID[2]}` == 'ACB' && !['na', 'ni', 'none', 'non', 'nil', 'n/a', 'n/l', 'nill'].includes(employeeAssessed.employee_first_name.toLowerCase()) && employeeAssessed.employee_first_name.length < 3)
                                    ||
                                    (assessmentQuestionsID != '3' && `${branchID[0]}${branchID[1]}${branchID[2]}` == 'ACB' && !['na', 'ni', 'none', 'non', 'nil', 'n/a', 'n/l', 'nill'].includes(employeeAssessed.employee_last_name.toLowerCase()) && employeeAssessed.employee_last_name.length < 3))
                                ){
                                    toast.remove(); toast.error('Please Enter Atleast One Valid Name of Employee', {duration: 7000});
                                } else {
                                    setIsPreQuestionAnswered(true);
                                    setShowPreQuestions(false);
                                }
                            } 
                            else { 
                                toast.remove(); 
                                toast.error(`Please Complete All Fields`) }
                            }
                        } 
                        className={`rounded px-2 py-4 mt-4 w-full text-white text-lg font-bold bg-sideBarBlue`}>{'Continue Evaluation'}</button>
                </form>
            </div>
            
        </div>
    )
}

export default EvaluatorSummaryForm