
import axios from 'axios';
import toast from 'react-hot-toast';

const baseURL = 'https://mysteryshopper.connectifie.com/api/v1.0';

export const signinEvaluator = async (formData) => {
    try {
        toast.remove();
        toast.loading('Signing you in...');
        const { data } = await axios.post(`${baseURL}/evaluator/login`, formData);
        toast.remove();
        console.log({data})
        const payload = data.success ? {...data, ...data.user[0], token: data.token} : {};
        data.success ? toast.success('You are now signed in', { duration: 7000 }) : toast.error('Login Failed! Try again');
        Object.entries({...payload}).forEach(data=> {
            sessionStorage[`${data[0]}`] = data[1];
        })
        return data.success ? payload : {token: false};
    } catch (error) {
        toast.remove();
        console.log(error.response);
        toast.error(error.message);
        return null;
    }
}; 
