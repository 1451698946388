import { useSelector, useDispatch } from "react-redux";
import DonutChart from "../../components/charts/DonutChart";
import ColumnChart from "../../components/charts/ColumnChart";
import ProgressBar from "../../components/charts/ProgressBar";
import { useEffect, useState } from "react";
import {
    AttachMoneyRounded,
    Assignment,
    AutorenewRounded,
    AssignmentTurnedIn,
    Flag,
    KeyboardArrowDownRounded,
    KeyboardArrowRightRounded,
    Lock,
    SearchRounded,
    BusinessRounded,
    BusinessCenterRounded,
    ReportProblemRounded,
    AccountBalanceWalletRounded,
} from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNairaSign, faInfoCircle, faExclamation, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { useNavigate } from "react-router-dom";
import { fetchQuestionsThunk } from "../assessment/assessmentSlice";
import toast from "react-hot-toast";

import { clearEvaluatorData, getEvaluatorDataThunk, getEvaluatorTaskThunk, setCampaignID, getCampaignTypesThunk, sendFeedbackThunk } from "./summarySlice";
import axios from "axios";




const date= new Date();
const hourOfDay = date.getHours();
const month = (date.getMonth()+1) > 9? date.getMonth()+1: (`0${date.getMonth() + 1}`);
const day = date.getDate() > 9? date.getDate() : `0${date.getDate()}`;
export const today= `${date.getFullYear()}-${month}-${day}`;

const convertDate = (dateString) => {
    //console.log({dateString});
    let str = dateString && dateString.split(" ")[0];
    //console.log({str});
    return str;
}



const EvaluatorSummary = () => {
    const auth = useSelector(state=> state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const evaluator_name = sessionStorage.getItem('evaluator_name');
    const evaluator_access_code = sessionStorage.getItem('evaluator_access_code');

    const { 
        wages,
        totalBranch,
        totalScopeBranch,
        totalSec,
        totalScopeSec,
        totalFle,
        totalScopeFle,
        totalIntern,
        totalScopeIntern,
        totalCompleted,
        totalRejected,
        totalPending,
        completionScope,
        allTaskAssign, //array of task objs per camapaign
        allAssessment, //array of assessment objs
        campaignID,
        campaignTypes,

    } = useSelector(state=> state.evaluatorData);


    const [allCampaign, setAllCampaign] = useState([]);

    useEffect(()=>{
        if(!auth.token) dispatch(clearEvaluatorData())
        axios.get(`https://mysteryshopper.connectifie.com/api/v1.0/campaign/all`, {
            headers: {
                Authorization: `Bearer ${auth.token}`,
            }
        }).then(response=> {
            //console.log({response : response.data});
            setAllCampaign(response.data);
        })
    }, [auth.token, dispatch])

    


    /*START OF ASSESSMENT HISTORY */

    // extract all dates from allAssessment history
    const allDates = allTaskAssign? allTaskAssign.map(task=> {
        return task.date_assigned && task.campaign_id == campaignID? convertDate(task.date_assigned) : null;
    }) : [];

    // extract uniques dates i.e remove duplicates
    const allDatesOfEvaluation = [...new Set(allDates)].sort().reverse();

    // filtered data to feed assessment history table
    const [selectAssessment, setSelectAssessment] = useState(allAssessment);

    const [dateFilter, setDateFilter] = useState(today); //default date filter is today
    const handleAssessmentDateChange = (e) => {
        //console.log(e.target.value);
        setDateFilter(e.target.value);
    }

    const [typeFilter, setTypeFilter] = useState(3); // 3: BRANCH, 2: SECURITY, 1: FLE.
    const handleAssessmentTypeChange= (e) =>{
        //console.log(e.target.value);
        setTypeFilter(Number(e.target.value));
    }

    useEffect(()=>{
        if(typeFilter && dateFilter){
            const filteredAssessment = allAssessment? allAssessment.filter(assessment=> {
                return convertDate(assessment.assigned_date) === dateFilter && assessment.assessment_type_id === typeFilter;
            }) : [];
            setSelectAssessment(filteredAssessment);
        }

    }, [typeFilter, dateFilter, allAssessment]);

    const [taskDetailsDisplay, setTaskDetailsDisplay] = useState('hidden');
    const [taskDetails, setTaskDetails] = useState({});
    const handleViewTaskDetails = (task) => {
        setTaskDetails(task);
        setTaskDetailsDisplay('');
    }
    
    /*END OF ASSESSMENT HISTORY */
    

    /*START OF TODAY'S TASKS */

    // Date filter state for tasks assigned to evaluator
    const [taskDate, setTaskDate] = useState(convertDate(today));
    //console.log(convertDate(today))
    const todayTask = allTaskAssign && allTaskAssign.length && allTaskAssign.filter(task=> task.date_assigned === taskDate && task.campaign_id == campaignID)// : [];
    console.log({ todayTask, allTaskAssign });
    const todayALLTask = allTaskAssign? allTaskAssign.filter(task=> task.date_assigned === taskDate && task.campaign_id == campaignID && (task.task_type == 'ALL' || task.task_type == 'All')) : [];
    const todaySINGLETask = allTaskAssign? allTaskAssign.filter(task=> task.date_assigned === taskDate && task.campaign_id == campaignID && task.task_type != 'ALL' && task.task_type != 'All') : [];
    const todayNumberOfTasks = allTaskAssign? allTaskAssign.filter(task=> task.date_assigned === taskDate && task.campaign_id == campaignID).length : 0;
    const todayTotalTask = (todayALLTask.length + (todayALLTask.length * 4) + (todayALLTask.length * 2)) + todaySINGLETask.length;
    
    const completedFLE = allAssessment? allAssessment.filter(report=> convertDate(report.assigned_date) === taskDate && report.assessment_type_id == 1 && report.status === "Completed") : [];
    const completedSecurity = allAssessment? allAssessment.filter(report=> convertDate(report.assigned_date) === taskDate && report.assessment_type_id == 2 && report.status === "Completed") : [];
    const completedBranch = allAssessment? allAssessment.filter(report=> convertDate(report.assigned_date) === taskDate && report.assessment_type_id == 3 && report.status === "Completed") : [];
    const completedIntern = allAssessment? allAssessment.filter(report=> convertDate(report.assigned_date) === taskDate && report.assessment_type_id == 4 && report.status === "Completed") : [];

    const totalCompletedFLE = completedFLE.length > 4 * todayNumberOfTasks? 4 * todayNumberOfTasks : completedFLE.length;
    const totalCompletedSecurity = completedSecurity.length > 2 * todayNumberOfTasks? 2 * todayNumberOfTasks : completedSecurity.length;
    const totalCompletedBranch = completedBranch.length > 1 * todayNumberOfTasks? 1 * todayNumberOfTasks : completedBranch.length;
    const totalCompletedIntern = completedIntern.length > 4 * todayNumberOfTasks? 4 * todayNumberOfTasks : completedIntern.length;

    //console.log({todayNumberOfTasks})

    const todayCompleted = allAssessment? totalCompletedFLE + totalCompletedSecurity + totalCompletedBranch + totalCompletedIntern : 0;

    console.log({ todayALLTask, todaySINGLETask, taskDetails })

    const dailyScopeCompletion = {
        assigned: todayTotalTask,
        done: todayCompleted
    }

    const allAssignedBranches = allTaskAssign? allTaskAssign.map(task=> task.branch_code || task.branch_id) : [];
    console.log({allAssignedBranches})

    const handleEvaluateAction= (e) =>{
        //console.log(e.target.value, e.target.name);
        const assessmentQuestionsID = Number(e.target.value);
        const branchID = e.target.name;
        //console.log({branchIDInAssessment: branchID})
        dispatch(fetchQuestionsThunk({assessmentQuestionsID, branchID}))
        .then(({meta}) => {
            //console.log({meta})
            if (meta.requestStatus === "fulfilled") {
                navigate('/form');
            }
        })
    }

    const [feedback, setFeedback] = useState({});
    const [feedbackDisplay, setFeedbackDisplay] = useState('hidden');
    const [controlFeedbackDisplay, setControlFeedbackDisplay] = useState(false);
    const handleFeedbackAction= (branchID) =>{
        const feedback_evaluator_id= evaluator_access_code;
        const feedback_branch_id = branchID;
        const campaign_id = campaignID;

        setFeedback({ feedback_evaluator_id, feedback_branch_id, campaign_id });
        setFeedbackDisplay('');
    }
    const handleComplaintSubmit = (e) => {
        e.preventDefault();
        console.log({feedback})
        if(!feedback.feedback || feedback.feedback == 'Select Complaint') { toast.remove(); return toast.error("Please Select a Complaint");}
        dispatch(sendFeedbackThunk(feedback))
        .then(({meta})=>{
            console.log({meta})
            if(meta.requestStatus == 'fulfilled'){
                setFeedbackDisplay('hidden');
            }
        })
    }

    const frequentComplaints = [
        'Bank not found in location', 'Staff not with name tag', 'Private Bank', 'Bank moved',
        'Bank Closed', 'No Customer Care Operative', 'Branch has very few staff', 'Branch is an ATM gallery',
        'The branch is an e-center', 'OTHERS',
    ]



    /*END OF TODAY'S TASKS */
    

    /*START OF EVALUATOR SUMMARY */

    // report cards
    const [showOverallCards, setShowOverallCards] = useState(false);

    // Reports categories

    const progress= completionScope? Math.round((completionScope.done/completionScope.assign)*100) : 0;
    //console.log({progress});

    const [showDisclaimer, setShowDisclaimer] = useState(false);

    /*END OF EVALUATOR SUMMARY */


    const [historyTaskDisplay, setHistoryTaskDisplay] = useState('hidden');
    const [historyTaskDetails, setHistoryTaskDetails] = useState({});

    const handleViewHistoryTaskDetails = (task) => {
        setHistoryTaskDetails(task);
        setHistoryTaskDisplay('');
        const element = document.getElementById('history-task-details');
        element.scrollIntoView({behavior: 'smooth'});
    }

    const handleClickCampaignDiv = (e) => {
        const element = document.getElementById(e.target.id);
        element.scrollIntoView({behavior: 'smooth'});
    }


    

    return (
        <div className="p-2 lg:px-12 mt-10 flex flex-col bg-slate-50 h-full">
            <div className="w-full md:w-1/2">
                <select onChange={(e)=> { dispatch(setCampaignID(e.target.value)); dispatch(getCampaignTypesThunk(e.target.value)); }}
                    defaultValue={campaignID}
                    value={campaignID} 
                    className="rounded bg-dayoAshBlue focus:outline-none focus:border-dayoLightBlue text-white py-1 text-center"
                >
                    
                    <option value={'DEFAULT'} disabled>Select Campaign ID</option>
                    {
                        allCampaign? allCampaign.map(campaign=> {
                            return <option key={campaign.campaign_id} value={campaign.campaign_id}>{campaign.campaign_name}</option>
                        }) : <option></option>
                    }
                </select>
            </div>

            <div className="my-3">
                <p className="font-bold text-slate-700">Welcome, <span className='text-dayoAshBlue hover:text-blue-600 capitalize'>{evaluator_name? ` ${evaluator_name.split(' ')[0]} (${evaluator_access_code})` : 'Stranger'}</span></p>
                <p className="text-slate-800 text-sm">                            
                    <FontAwesomeIcon className="text-gray-600" icon={faNairaSign} />
                    <span className="text-green-800"> {wages}</span>
                </p>
                <small className=" text-slate-800 text-xs"><FontAwesomeIcon className=" text-blue-800 text-2xs" icon={faInfoCircle} /> Wages are subject to review</small>
            </div>

            <button onClick={(e)=>{ setShowOverallCards(!showOverallCards)}} className={`w-max px-3 py-1 rounded bg-sideBarBlue text-slate-100 hover:animation-none`}><span className={`${showOverallCards? 'motion-safe:animate-pulse' : 'animate-none'}`}>Tasks Summary </span> {showOverallCards? <KeyboardArrowDownRounded className="'animate-none'" /> : <KeyboardArrowRightRounded className="motion-safe:animate-pulse" />}</button>
            

            <section className={`${showOverallCards? 'visible' : 'hidden'} flex flex-col items-center justify-center mb-3 shadow rounded `}>
                <div className="flex w-full items-center justify-center" >
                    {
                        campaignID != 3?
                        <>
                        <div className="bg-white shadow rounded border w-1/3 m-1 p-2">
                            <h2 className="font-bold text-center text-slate-800 text-md">BRANCH
                            <br />
                            <small className="w-min text-xs">Done / Assigned</small>
                            </h2>
                            
                            <div className="flex items-center justify-center mt-4">
                                
                                <p className="font-bold text-lg text-blue-600"><span className="text-sideBarBlue">{totalBranch}</span> / {`${totalScopeBranch}`}</p>
                            </div>
                        </div>
                        <div className="bg-white shadow rounded border w-1/3 m-1 p-2">
                            <h2 className="font-bold text-center text-slate-800 text-md">FLE
                            <br />
                            <small className="w-min text-xs">Done / Assigned</small>
                            </h2>
                            <div className="flex items-center justify-center mt-4">

                            <p className="font-bold text-lg text-blue-600"><span className="text-sideBarBlue">{totalFle}</span> / {`${totalScopeFle}`}</p>                            </div>
                        </div>
                        <div className="bg-white shadow rounded border w-1/3 m-1 p-2">
                            <h2 className="font-bold text-center text-slate-800 text-md">SECURITY
                            <br />
                            <small className="w-min text-xs">Done / Assigned</small>
                            </h2>
                            <div className="flex items-center justify-center mt-4">

                            <p className="font-bold text-lg text-blue-600"><span className="text-sideBarBlue">{totalSec}</span> / {`${totalScopeSec}`}</p>                            </div>
                        </div>
                        </>
                        :
                        <div className="bg-white shadow rounded border w-1/2 m-1 p-2">
                            <h2 className="font-bold text-center text-slate-800 text-md">INTERN
                            <br />
                            <small className="w-min text-xs">Done / Assigned</small>
                            </h2>
                            <div className="flex items-center justify-center mt-4">

                            <p className="font-bold text-lg text-blue-600"><span className="text-sideBarBlue">{totalIntern}</span> / {`${totalScopeIntern}`}</p>                            </div>
                        </div>
                    }
                </div>
                
            </section>

            <section className="my-2 py-4 ">
                <div className="flex items-center justify-between mx-1">
                    <h2 className="self-start font-bold text-slate-600 text-xl">Daily Tasks</h2>
                    {
                        evaluator_access_code === "KID101"? (
                            <select onChange={(e)=> { setTaskDate(e.target.value) }} className="px-3 rounded border text-slate-500 focus:outline focus:outline-sideBarBlue">
                                <option value={today}>Today</option>
                                { // Date selector for daily tasks
                                    allDatesOfEvaluation.length? allDatesOfEvaluation.filter(date=> date !== today).map((date, i)=>{
                                        return <option key={i} value={date}>{date}</option>
                                    }) : null
                                }
                            </select>
                        ) : null
                    }
                </div>
                
                {
                    todayTask && todayTask.length? (
                    <>
                        <div className="flex my-2 w-full overflow-x-scroll">
                        { 
                            todayTask.length && [...new Set(todayTask)].map((task, i)=>{
                                // console.log({task})
                                return (
                                    <div key={i} className="rounded-md border p-3 m-1 bg-white shadow-md w-1/2 min-w-min">
                                        <p className="font-bold text-slate-600">Branch <br/><span className={`font-bold text-accessOrange`}>{task.branch_code || task.branch_id}</span></p>
                                        <p className="font-bold text-slate-600 ">Assessment: <br/><span className="text-slate-500 font-bold">{task.task_type}</span></p>
                                        <button onClick={()=>{ handleViewTaskDetails(task) }} className="flex flex-wrap justify-center items-center border hover:border-sideBarBlue font-bold p-1 mt-3 text-sm text-sideBarBlue w-full rounded hover:shadow-xs border-dayoLightBlue"><SearchRounded /> View Task</button>
                                    </div>
                                )
                            })
                        } 
                        </div>
                        
                        <div className="bg-white rounded border py-3 md:w-full md:flex md:justify-center">
                            <DonutChart data={[dailyScopeCompletion.done, ((dailyScopeCompletion.assigned /*+ (dailyScopeCompletion.assigned * 4) + (dailyScopeCompletion.assigned * 2) */) - dailyScopeCompletion.done)]} titleText={`Daily Task Completion: ${dailyScopeCompletion.done} / ${/*(*/dailyScopeCompletion.assigned /*+ (dailyScopeCompletion.assigned * 4) + (dailyScopeCompletion.assigned * 2) )*/}`} />
                        </div>
                    </>
                    )  : <p className="text-center text-slate-500 my-3">No Task Assigned for Today{/*taskDate*/}</p>
                }
            </section>
        
        {/**Fixed Section div to display task details and submission form links*/}
            <section className={`fixed mx-2 md:mx-5 h-screen w-11/12 bg-transparent flex items-start justify-center ${taskDetailsDisplay}`}>
                <div className={`flex flex-col h-max min-w-full bg-dayoLightBlue rounded border border-slate-300 shadow-lg ${taskDetailsDisplay}`}>
                    <div className="flex justify-between items-center min-w-full p-4 my-1 bg-white">
                        <h1 className="font-bold text-slate-600">{`${taskDetails.bank? taskDetails.bank : taskDetails.bank === 'GTB'? 'GT' : ''} BANK (${taskDetails.code? taskDetails.code : ''})`} </h1>
                        <button onClick={()=>{ setTaskDetailsDisplay('hidden') }} className="rounded-full shadow-inset bg-red-600 hover:bg-red-500 active:bg-red-500 px-2 text-white font-bold">X</button>
                    </div>
                    <div className="flex flex-col  p-2">
                        <h1 className="capitalize font-bold text-slate-500 my-5">{`${taskDetails.name? `${taskDetails.name.toLowerCase()},` : ''} ${taskDetails.state? taskDetails.state : ''}`} </h1>
                        <p className="font-bold text-slate-500">Address: <br/> <span className="text-slate-700">{taskDetails.address? taskDetails.address : ''}</span></p>
                    </div>        
                    <div className="flex flex-col md:flex-row items-center justify-center p-3">
                        { (hourOfDay >= 8 && hourOfDay < 17) || evaluator_access_code == 'KID101'? (
                            <>
                                {
                                    campaignTypes && campaignTypes.map(type=> {
                                        if(taskDetails.task_type !== "ALL"){
                                            return type.type === taskDetails.task_type && <button onClick={handleEvaluateAction} value={type.assessment_type_id} name={taskDetails.branch_id || taskDetails.branch_code} className="md:w-1/3 w-full p-3 md:mx-1 my-1 rounded border border-blue-600 text-blue-600 hover:text-slate-100 hover:bg-blue-500 "><BusinessCenterRounded />{`Evaluate ${type.type} `}</button>
                                        } else{
                                            return <button onClick={handleEvaluateAction} value={type.assessment_type_id} name={taskDetails.branch_id || taskDetails.branch_code} className="md:w-1/3 w-full p-3 md:mx-1 my-1 rounded border border-blue-600 text-blue-600 hover:text-slate-100 hover:bg-blue-500 "><BusinessCenterRounded />{`Evaluate ${type.type} `}</button>
                                        }
                                   })
                                }
                                <button onClick={(e)=>{ handleFeedbackAction(e.target.value); setTaskDetailsDisplay('hidden'); }} value={taskDetails.branch_id || taskDetails.branch_code} className="md:w-1/3 w-full p-3 md:mx-1 my-1 rounded border border-red-600 text-red-600 hover:text-slate-100 hover:bg-red-500 "><ReportProblemRounded />Report Branch</button>
                            </>
                            )
                            : <button onClick={()=> { toast.remove(); toast.error('Out of time range, please wait!') }} className="md:w-1/3 w-full p-3 md:mx-1 my-1 rounded border border-slate-400 text-slate-400 hover:text-slate-100 hover:bg-slate-300 disabled"><Lock /> Evaluate</button>                       
                        }
                    </div>                        
                </div>
            </section>

        {/**Feedback form for evaluators */}
            <section className={`fixed mx-2 md:mx-5 h-screen w-11/12 bg-transparent flex items-start justify-center ${feedbackDisplay}`}>
                <div className={`flex flex-col h-max min-w-full bg-dayoRed rounded border border-slate-300 shadow-lg ${feedbackDisplay}`}>
                    <div className="flex justify-between items-center min-w-full p-4 my-1 bg-white">
                        <h1 className="font-bold text-slate-600">{`${taskDetails.bank? taskDetails.bank : taskDetails.bank === 'GTB'? 'GT' : ''} BANK (${taskDetails.code? taskDetails.code : ''})`} </h1>
                        <button onClick={()=>{ setFeedbackDisplay('hidden') }} className="rounded-full shadow-inset bg-red-600 hover:bg-red-500 active:bg-red-500 px-2 text-white font-bold">X</button>
                    </div>
                    <form 
                        onSubmit={handleComplaintSubmit} 
                        onChange={(e)=>{ setFeedback(prev=> ({...prev, [e.target.name]: e.target.value})) }} 
                        className="flex flex-col justify-center items-center h-max w-full min-w-max p-3"
                    >
                        <p className="text-slate-600 font-bold text-lg">Please Select Your Complaint</p>
                        <select required name='feedback' className="w-full py-2 my-1 rounded-md border border-slate-200 focus:outline-none" onChange={(e)=> { e.target.value == 'OTHERS'? setControlFeedbackDisplay(true) : setControlFeedbackDisplay(false)}}>
                            <option disabled>Select Complaint</option>
                            {
                                frequentComplaints.map(complaint=> {
                                    return <option>{complaint}</option>
                                })
                            }
                        </select>
                        {
                            controlFeedbackDisplay? <textarea required type='text' className="w-full py-3 my-2 rounded-md border border-slate-200 focus:outline-none" placeholder='Tell us the issue' name='feedback' /> : null
                        }
                        <input type='submit' value="Submit Complaint" className="rounded w-3/4 p-2 my-2 border border-red-500 text-slate-100 bg-red-500 active:bg-red-400" />
                    </form>                
                </div>
            </section>


        {/**Evaluator's campaign summary */}
            <section className=" p-2 my-4 shadow">
                <h2 id='campaign-summary' onClick={handleClickCampaignDiv} className="font-bold text-xl text-slate-600 mb-2">Campaign Summary</h2>
                <div className="py-3 lg:p-8">
                    <p className="text-slate-600 text-sm">Scope Completion</p>
                    <ProgressBar progressPercentage={progress > 100 || progress == 'Infinity'? 100 : !progress? 0 : progress} />
                </div>
                <div className="p-1 flex flex-wrap lg:flex lg:flex-wrap lg:p-6">
                    <div className="flex flex-col items-center w-full bg-white shadow border rounded p-2 my-1 font-bold lg:py-6 lg:w-1/4 lg:m-1 text-slate-700">
                        <p>You have earned <span onClick={(e)=> setShowDisclaimer(!showDisclaimer)}><FontAwesomeIcon className="ml-2 text-blue-600 " icon={faInfoCircle} beat /></span></p>
                        {
                            showDisclaimer === false?
                            <div className="flex items-center justify-center w-full p-2 my-1 font-bold lg:py-6 lg:w-1/4 lg:m-1 text-slate-700">
                                <FontAwesomeIcon className="mr-2 text-4xl text-gray-600" icon={faNairaSign} />
                                <span className="text-gray-600 text-5xl">{wages}</span>
                            </div>
                            :
                            <div className="flex items-center justify-center w-full p-2 my-1 lg:py-6 lg:w-1/4 lg:m-1 text-slate-700">
                                <FontAwesomeIcon className="mr-4 font-bold text-4xl text-red-600" icon={faExclamationTriangle} />
                                <span className="text-gray-600 font-normal ">Note that <strong className="text-blue-900">wages are subject to review</strong> based on quality of entry and other objective factors.</span>
                            </div> 
                        }               
                    </div>
                    <div className="flex flex-col w-1/2 bg-white shadow border rounded p-2 my-1 font-bold lg:py-6 lg:w-1/4 lg:m-1 text-slate-600">
                        <Assignment className="mr-4 text-sideBarBlue" />Tasks Assigned  
                        <span className="text-sideBarBG ">{ completionScope? completionScope.assign : 0 }</span>
                    </div>
                    <div className="flex flex-col w-1/2 bg-white shadow border rounded p-2 my-1 font-bold lg:py-6 lg:w-1/4 lg:m-1 text-slate-600">
                        <AutorenewRounded className="animate-spin" />
                        Reports Pending  
                        <span className="text-slate-600">{ totalPending }</span>
                    </div>
                    <div className="flex flex-col w-1/2 bg-white shadow border rounded p-2 my-1 font-bold lg:py-6 lg:w-1/4 lg:m-1 text-slate-600">
                        <AssignmentTurnedIn className="mr-4 text-green-600" />
                        Reports Approved  
                        <span className="text-green-600">{ totalCompleted }</span>
                    </div>
                    <div className="flex flex-col w-1/2 bg-white shadow border rounded p-2 my-1 font-bold lg:py-6 lg:w-1/4 lg:m-1 text-slate-600">
                        <span className="mr-4 text-red-600"><ReportProblemRounded className="animate-pulse" /></span>Reports Rejected  <span className="text-red-600">{ totalRejected }</span>
                    </div>
                </div>
            </section>

            <section className="my-3">
                <div className="flex justify-around items-center p-1 border bg-dayoAshBlue">
                    <h2 className="font-bold text-slate-100">Reports History</h2>
                    <select defaultValue={today} onChange={handleAssessmentDateChange} className="px-2 py-1 mx-1 bg-slate-50 focus:outline-none focus:border focus:border-dayoAshBlue rounded">
                        <option value={today}>Today</option>
                        {
                            allDatesOfEvaluation.map((date, i)=> {
                                return <option key={i}>{date}</option>
                            })
                        }
                    </select>
                    <select defaultValue="DEFAULT" onChange={handleAssessmentTypeChange} className="px-2 py-1 bg-slate-50 focus:outline-none focus:border focus:border-dayoAshBlue rounded">
                        <option value="DEFAULT" disabled>Task Types</option>
                        <option value={3}>Branch</option>
                        <option value={1}>FLE</option>
                        <option value={2}>Security</option>
                        <option value={4}>Intern</option>
                    </select>
                </div>
                <div className="overflow-x-scroll">
                    <table className="table-auto w-full">
                        <thead className='text-center w-max text-slate-700 shadow m-1 border'>
                            <tr>
                            {
                                ['AssessmentType', 'Branch', 'Status'].map((th, index) => <th key={index}>{th}</th>)
                            }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectAssessment? selectAssessment.map((assessment, index) => {
                                    return (
                                        <tr key={index} className='text-center w-max text-slate-700 shadow m-1 border'>
                                            <td className="text-sm p-2 ">{assessment.assessment_type_id === 3? 'Branch' : assessment.assessment_type_id === 1? 'FLE' : assessment.assessment_type_id === 4? 'Intern' : 'Security'}</td>
                                            <td className="text-sm p-2 text-blue-900 ">{assessment.branch_code}</td>
                                            <td className={`text-sm p-2 ${assessment.status === 'Completed'? 'text-blue-700' : assessment.status === 'Rejected'? 'text-red-700 bg-red-200 rounded' : 'text-slate-600'} `}>{assessment.status}</td>
                                            <td onClick={()=>{ handleViewHistoryTaskDetails(assessment) }} className="underline text-slate-300 disabled">view</td>
                                        </tr>
                                    )
                                }) : <tr><td>No Data</td></tr>
                                
                            }
                        </tbody>
                    </table>

                    {/*Task history details */}
                    <div id="history-task-details" className={`flex flex-col h-max min-w-full bg-dayoLightBlue rounded border border-slate-300 shadow-lg ${historyTaskDisplay}`}>
                        <div className="flex justify-between items-center min-w-full p-4 my-1 bg-white">
                            <h1 className="font-bold text-slate-600">{`${historyTaskDetails.bank_name? historyTaskDetails.bank_name : historyTaskDetails.bank_name === 'GTB'? 'GT' : ''} BANK (${historyTaskDetails.branch_code? historyTaskDetails.branch_code : ''})`} </h1>
                            <button onClick={()=>{ setHistoryTaskDisplay('hidden') }} className="rounded-full shadow-inset bg-red-600 hover:bg-red-500 active:bg-red-500 px-2 text-white font-bold">X</button>
                        </div>
                        <div className="flex flex-col  p-2">
                            <h1 className="capitalize font-bold text-slate-500 my-5">{`${historyTaskDetails.branch_name? `${historyTaskDetails.branch_name.toLowerCase()},` : ''} ${historyTaskDetails.state? historyTaskDetails.state : ''}`} </h1>
                            <p className="font-bold text-slate-500">Address: <br/> <span className="text-slate-700">{historyTaskDetails.address? historyTaskDetails.address : ''}</span></p>
                        </div>        
                        <div className="flex flex-col md:flex-row items-center justify-center p-3">
                            
                        </div>                        
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EvaluatorSummary;