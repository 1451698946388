import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { signInEvaluatorThunk, signInThunk } from './authSlice';
import toast from 'react-hot-toast';
import AdminSignin from './signInForms/AdminSignin';
import EvaluatorSignin from './signInForms/EvaluatorSignin';
import logo from '../../utilities/assets/connectifieLogo.svg';
import backgroundImage from "../../utilities/assets/signinBGBlue.svg";
import ladylaptop from "../../utilities/assets/ladylaptop.svg";
import blueOval from "../../utilities/assets/blueOval.svg";
import bigCircle from "../../utilities/assets/bigCircleTopLeft.svg";
import furnitureGroup from "../../utilities/assets/furnitureOpacityGroup.svg";
//import AdminHeader from '../../components/AdminHeader/AdminHeader';

//const useAnimSnippet= <UseAnimations size={40} wrapperStyle={{ marginTop: '5px' }} animation={checkBox} />

export const greetTime = () => {
	const today = new Date();
	const hourOfDay = today.getHours(); //The current hour of today
	if (hourOfDay > 11 && hourOfDay <= 16) {
		return 'afternoon';
	} else if (hourOfDay > 16) {
		return 'evening';
	} else {
		return 'morning';
	}
}

export function SigninPage() {
	const { register, handleSubmit } = useForm();
	const dispatch = useDispatch();

	const auth = useSelector((state) => state.auth);

	const navigation = useNavigate();

	// navigate to dashboard on auth
	useEffect(() => {
		//const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : false;
		const { token } = auth;
		if ( token ) {
			navigation('/evaluator');
		}
	}, [auth, navigation]);

	const onEvaluatorSignin = (e) => {
		dispatch(signInEvaluatorThunk({...e}));
	};

	return (
		<div
			className={`flex bg-white w-full min-h-max`}
			style={{ backgroundSize: 'cover', height: '100vh' }}
		>
			{/*<AdminHeader />*/}
			<div className='flex justify-center items-center w-1/3 md:w-2/3'>
				<img src={bigCircle} alt='bigCircle' className='fixed top-1/4 w-1/3 md:-top-1/2 md:-left-1/3 md:w-5/6 md:h-5/6 lg:-top-1/2 lg:-left-1/3 lg:w-9/12 lg:h-19/12' />
				<img src={furnitureGroup} alt='furnitures Background' className='fixed z-0 md:w-1/2 mdw-1/3 mdh-1/3' />
				
				<div className='flex justify-center items-center overflow-x-hidden h-full '>
					<img src={blueOval} className='hidden md:block h-44 bottom-1/4 -right-8 md:h-38 relative md:bottom-56 md:left-32' alt='lady holding a laptop'/>
					<img src={ladylaptop} className='h-3/4 md:h-3/5 z-10' alt='lady holding a laptop'/>
				</div>
				
			</div>
			<div 
				className="z-10 bg-white shadow-2xl md:rounded-none p-4 flex flex-col justify-center items-center md:w-1/3 h-full md:h-full lg:w-1/2 justify-self-end"
				style={{ 
					backgroundImage: `url(${backgroundImage})`, 
					backgroundSize: 'cover', height: '100vh' 
				}}
			>
				<img className="w-1/2 lg:w-1/3 mb-10" src={logo} alt="logo" />
				
				<EvaluatorSignin onSubmitForm={onEvaluatorSignin} />
			
			</div>
		</div>
	);
}

export default SigninPage;
