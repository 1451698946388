import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getEvaluatorData, getCampaignTypes, getEvaluatorTasks, sendFeedback } from "./summaryAPI";

const initialState = {};
const reducers = {
    setCampaignID: (state, action) =>{
        sessionStorage.setItem("campaignID", action.payload);
        return {...state, campaignID: action.payload}
    }, 
    clearEvaluatorData: (state, action) =>{
        return {};
    }
};

export const getEvaluatorDataThunk = createAsyncThunk('evaluatorData/load', async ({access_code, campaignID, makeToast}, { getState })=>{
    const { token } = getState().auth;
    const payload = await getEvaluatorData({access_code, campaignID, makeToast, token});
    return payload;
});

export const getCampaignTypesThunk = createAsyncThunk('evaluatorData/campaignTypes', async (campaignID, { getState })=>{
    const { token } = getState().auth;
    const payload = await getCampaignTypes(campaignID, token);
    return payload;
});

export const sendFeedbackThunk = createAsyncThunk('evaluatorData/feedback', async (formData, { getState })=>{
    const { token } = getState().auth;
    const payload = await sendFeedback(formData, token);
    return payload;
});

/*export const getEvaluatorTaskThunk = createAsyncThunk('evaluatorTask/load', async ({access_code, campaignID}, { getState })=>{
    const { token } = getState().auth;
    const payload = await getEvaluatorTasks(access_code, campaignID, token);
    return payload;
});*/







const evaluatorData = createSlice({
    name: 'evaluatorData',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            .addCase(getEvaluatorDataThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(getEvaluatorDataThunk.rejected, (state, action)=> ({ }))
            //.addCase(getEvaluatorDataThunk.rejected, (state, action)=> ({ }))
            .addCase(getCampaignTypesThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            
    }
});

export const { setCampaignID, clearEvaluatorData } = evaluatorData.actions;

export default evaluatorData.reducer;
