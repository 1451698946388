import axios from "axios";

const baseURL = 'https://mysteryshopper.connectifie.com/api/v1.0';

export const fetchBranchList = async (token) => {
    const { data } = await axios.get(`${baseURL}/branch-list?campaign_id=2`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    const branches = data.map(branch=>{
        return {
            name: branch.branch_name,
            address: branch.address,
            code: branch.branch_code,
            bank: branch.bank_name,
            state: branch.state,
            zone: branch.zone,
            group: branch.group_id
        }
    })

    return branches;

}