const ProgressBar = ({ progressPercentage, now, max, label }) => {

    const progress = progressPercentage >= 0? progressPercentage : now / max * 100;
    const progressColor= progress < 45 ? 'bg-red-600' : progress < 60? 'bg-orange-400' : 'bg-sideBarBlue';
    //console.log( progress );
    return (
        <div className="h-3 w-full bg-gray-300 rounded-full ">
            <div 
                className={`flex items-center justify-center rounded-full h-full text-white text-center ${progressColor}`} 
                style={{ width: `${progress}%`}}
            >
                <p className="font-bold text-shadow text-xs min-w-max ">{progressPercentage? `${progress} %` : label}</p>
            </div>
        </div>
    )
};

export default ProgressBar;