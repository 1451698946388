
import axios from 'axios';
import toast from 'react-hot-toast';

const baseURL = 'https://mysteryshopper.connectifie.com/api/v1.0';

export const fetchAssessmentQuestions = async (questionTypeID = 4, token) => {
    try {
        const { data: questions } = await axios.get(`${baseURL}/assessment/question/${questionTypeID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        //console.log({ questions });
    
        return questions;
    } catch (e) {
        console.log(e.message);
    }
}

export const submitAssessment = async (assessment, token) => {
    try {
        //if (assessment.assessment_type_id === 4) { // for ALL assessment: confusing /intern/ endpoint should change
            console.log("Got here")
            const { data: result } = await axios.post(`${baseURL}/evaluator/intern/save-assessment?campaign_id=${assessment.campaign_id}`, assessment, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log({ assessment, result });
            result.success? toast.success(result.msg, { duration: 7000, }): toast.error(result.msg, { duration: 7000, });
        
            return result;


    } catch (e) {
        if (e.response.status === 401) {
            toast.error('Error! Assessment not submitted. Please log in and try again.', {duration: 4000});
        }
        console.log({e});
    }
}