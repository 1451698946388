//import { PersonRounded } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOutThunk } from '../../features/authentication/authSlice';
import logoWhite from '../../utilities/assets/logoWhite.svg';
import headerLogoBG from '../../utilities/assets/headerLogoBG.svg';

const EvaluatorHeader = () => {
	const dispatch= useDispatch();

	const handleLogout= (e) =>{
		dispatch(signOutThunk());
		sessionStorage.removeItem('token');
		//console.log(sessionStorage.getItem('token'));
	}

	return (
		<div className="w-full h-10 md:h-1/4 md:mb-6 bg-slate-100 sticky top-0 z-40 ">
			<div className="h-full px-2 py-1 flex items-center justify-between">
				<div className="flex items-center h-full w-1/5 min-w-max">
					{/*<h1 className='text-orange-400 font-bold text-sm shadow-inner w-max'>Connectifie-MS</h1>*/}
					<img src={headerLogoBG} alt="Connectifie-MS" className="w-1/3 md:w-1/4 lg:w-150 fixed -left-4 md:-left-8 -top-14 md:-top-32 lg:-top-52 xl:-top-80" />
					<img src={logoWhite} alt="Logo" className="w-full md:w-3/5  h-3/4 z-10" />
				</div>
				<div className="flex w-max items-center">
					<button onClick={handleLogout} className="bg-sideBarBlue hover:bg-red-900 px-2 py-1 rounded text-white capitalize text-sm">
						log out
					</button>
				</div>
			</div>
		</div>
	);
};

export default EvaluatorHeader;
