import React from 'react'
import { useParams , useNavigate} from 'react-router-dom'
import axios from 'axios'
import {useState, useEffect} from 'react'
import { Col, Alert, Card, Row, Badge, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import QuestionCard from './questionCard';
import toast from 'react-hot-toast';





const FormStep = (props) => {
	const { register, handleSubmit, formState, getValues } = useForm();

	const handleSingleFormSubmit = (data) => {
        const newData = Object.entries(data).map(([key, value]) => ({
            question_id: key.split('_')[1],
            answer: value
        }));
        //console.log({newData})

        toast.remove();
		newData[0].answer === 'Select Option'? toast.error('Please select a valid answer') : props.updateFormData(newData);
        //console.log({answerData : newData})
        //console.log(data, "dataaaaaaa")
	};

	if (props.step !== props.currentStep) return null;


	return (
        <>
		<form
			onSubmit={handleSubmit(handleSingleFormSubmit)}
            className='h-3/4 max-h-3/4 overflow-y-auto'
			style={{ display: props.step === props.currentStep ? 'block' : 'none' }}
		>
		{
            props.questions.map((question, index) => { 
                console.log({ question })
                return (
                <div key={`Question:${question.question_id}`} className='h-3/4'>
                    <h1 className=' mb-6 text-lg md:text-xl text-blue-900 text-center'>{props.currentStep +1}. Question Category: {question.question_category}</h1>
                    <QuestionCard text={question.question} sn={index} />
                
                    { 
                    question.question_No_of_option < 1 || !question.question_No_of_option || question.question_No_of_option == 1?
                        <input 
                            type='text' 
                            className='p-4 mt-10 border border-gray-400 rounded w-full text-lg text-blue-900 object-contain focus:outline focus:outline-blue-500'
                            placeholder='Write Answer Here'
                            {...register(`question_${question.question_id}`, {
                                required: true,
                            })
                            } 
                        /> 
                    :
                    
                    question.question_No_of_option > 1 && question.question_No_of_option < 4 ?
                        <div className='flex flex-col box-border border-y-4 border-dayoLightBlue mt-5 h-full overflow-scroll'>
                            {question.question_option_1 && 
                            <label className='flex justify-start items-center mt-2 py-4 text-lg '>
                                <input className='mr-4 form-radio w-6 h-6 text-blue-500 outline-none'
                                    value={question.question_option_1}
                                    type='radio'
                                    name={`question_${question.question_id}`}
                                    {...register(`question_${question.question_id}`, {
                                        required: true,
                                        })
                                    }
                                 
                                /> {question.question_option_1}
                            </label>
                            }

                            {question.question_option_2 &&
                            <label className='flex justify-start items-center mt-2 py-4 text-lg '>
                                <input className='mr-4 form-radio w-6 h-6 text-blue-500 outline-none'
                                    value={question.question_option_2}
                                    type='radio'
                                    name={`question_${question.question_id}`}
                                    {...register(`question_${question.question_id}`, {
                                        required: true,
                                        })
                                    }
                                /> {question.question_option_2}
                            </label>
                            }

                            {question.question_option_3 &&
                            <label className='flex justify-start items-center mt-2 py-4 text-lg '>
                                <input className='mr-4 form-radio w-6 h-6 text-blue-500 outline-none'
                                    value={question.question_option_3}
                                    type='radio'
                                    name={`question_${question.question_id}`}
                                    {...register(`question_${question.question_id}`, {
                                        required: true,
                                        })
                                    }
                                /> {question.question_option_3}
                            </label>
                            }
                        </div>
                    :
                        <select required defaultValue={null}
                            className='p-4 mt-20 border border-gray-400 rounded w-full text-lg text-blue-900 object-contain focus:outline focus:outline-blue-500'
                        {...register(`question_${question.question_id}`, {
                            required: true,
                        })}
                        >
                            <option className='w-full' value={null} selected disabled >Select Option</option>
                            {question.question_option_1 && 
                            <option className='w-full' value={question.question_option_1}>{question.question_option_1}</option>
                            }  
                            
                            {question.question_option_2 && 
                            <option className='w-full' value={question.question_option_2}>{question.question_option_2}</option>
                            }  
                            {question.question_option_3 && 
                            <option className='w-full' value={question.question_option_3}>{question.question_option_3}</option>
                            }  
                            {question.question_option_4 && 
                            <option className='w-full' value={question.question_option_4}>{question.question_option_4}</option>
                            } 
                            {question.question_option_5 && 
                            <option className='w-full' value={question.question_option_5}>{question.question_option_5}</option>
                            } 
                            {question.question_option_6 && 
                            <option className='w-full' value={question.question_option_6}>{question.question_option_6}</option>
                            }
                            {question.question_option_7 && 
                            <option className='w-full' value={question.question_option_6}>{question.question_option_7}</option>
                            } 
                            {question.question_option_8 && 
                            <option className='w-full' value={question.question_option_6}>{question.question_option_8}</option>
                            } 
                            {question.question_option_9 && 
                            <option className='w-full' value={question.question_option_6}>{question.question_option_9}</option>
                            } 
                            {question.question_option_10 && 
                            <option className='w-full' value={question.question_option_6}>{question.question_option_10}</option>
                            } 

                        </select>
                    }
                    </div>
                )}
            )
        }
			<input type="submit" 
                id='singleSubmit'
                className={`fixed bottom-4 left-0 rounded px-2 py-4 mt-28 w-full text-white text-lg font-bold ${props.step === props.finalStep ? 'bg-accessOrange' : 'bg-blue-500'}`} 
                value={props.step === props.finalStep ? 'Submit Entry' : 'Next Step'} 
            />
		</form>
        </>
	);
}

export default FormStep;