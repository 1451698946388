import { useForm } from "react-hook-form";
import { greetTime } from "../Signin";



const EvaluatorSignin = ({onSubmitForm}) => {
	const { register, handleSubmit } = useForm();

    return (
        <form className="w-full md:w-3/4 lg:w-1/2" onSubmit={handleSubmit(onSubmitForm)}>
					<div className="flex md:items-center justify-start w-full  overflow-hidden">
						{/*<img className="object-contain h-full" src="/image.png" alt="logo" /> */}
						<h1
							className="text-xl w-full font-medium wider-all"
							style={{ color: '#22A2F1' }}
						>
							Welcome, Mystery Shopper
						</h1>
					</div>
					<p className="my-4 text-slate-500">
						Good {greetTime()}, Please sign into your account
					</p>
					<input
						id="access_code"
						className="block w-full p-2 bg-white rounded-md mb-4 border border-blue-300 focus:outline-0 focus:border-accessOrange hover:border-accessOrange uppercase"
						type="text"
						{...register('access_code', {
							required: 'required',
						})}
						placeholder="Enter Access Code"
					/>
					<span className="text-slate-700">
						<input type="checkbox" className="form-checkbox rounded focus:border-none focus:outline-none focus:ring-0" name="keep_signed" style={{ color: '#22A2F1' }} /> Keep me signed in
					</span>
					<br />
					<input
						id="submit"
						type="submit"
						value="Sign in"
						className="bg-accessOrange text-white w-full mt-4 mx-auto rounded-md  font-bold py-2 active:outline active:outline-orange-300"
					/>
				</form>
    )
}

export default EvaluatorSignin;