import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
/*import {
	ChevronRightRounded,
	KeyboardArrowDownRounded
} from '@material-ui/icons';*/
import EvaluatorHeader from '../components/evaluatorHeader/EvaluatorHeader';
import { branchListThunk } from '../features/branch/branchSlice';
import EvaluatorSummary from '../features/summary/EvaluatorSummary';
import { getCampaignTypesThunk, getEvaluatorDataThunk, getEvaluatorTaskThunk, setCampaignID } from '../features/summary/summarySlice';

const pages = [
	{
		id: 0,
		title: 'My Summary',
		// icon: <HomeRounded />,
		page: <EvaluatorSummary />,
	},
];

const EvaluatorDashboard = () => {
	const dispatch = useDispatch();
	const navigation = useNavigate();
	const { campaignID: campaign_id } = useSelector(state=> state.evaluatorData);
	const auth = useSelector((state) => state.auth);
	const campaignID = sessionStorage.getItem("campaignID")? sessionStorage.getItem("campaignID") : 2;


    // get value from session storage, if null default to 0
	const [currentPageID, setCurrentPageID] = useState(
		sessionStorage.getItem('evalPage') ? Number(JSON.parse(sessionStorage.getItem('evalPage'))) : 0
	);

	/*setInterval(()=> {
		const date= new Date();
		const hourOfDay = date.getHours();
		toast.loading(`The time is ${hourOfDay}`)
		//return hourOfDay <= 8 && hourOfDay >= 17? Windows.refresh() : dispatch(branchListThunk());
	}, /*1800 * 1000);*/

	useEffect(()=>{
		const token = sessionStorage.token? sessionStorage.token : false;
		const evaluator_access_code = sessionStorage.getItem('evaluator_access_code');
		if (token) {
			dispatch(getEvaluatorDataThunk({access_code: evaluator_access_code, campaignID}));
			dispatch(branchListThunk());
			dispatch(setCampaignID(campaignID));
			dispatch(getCampaignTypesThunk(campaignID));
			//dispatch(getEvaluatorTaskThunk({access_code: evaluator_access_code, campaignID}))
		}
	}, [auth.token, campaignID, dispatch])
		
	useEffect(()=>{
		const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : false;
		if (!token) {
			navigation('/');
			toast.remove();
			toast('You have been logged out');
		} else{
			navigation('/evaluator');
		}
	}, [navigation, auth]);

	//const setSelectedPageID = (id) => {
	//	setCurrentPageID(id);
	//	// persist selected page in session storage
	//	sessionStorage.setItem('evalPage', id);
	//};

	return (
		<div>
			<EvaluatorHeader />
			<div className="flex h-full w-full bg-slate-50" style={{ height: '100%'}}>
				{/* map through pages and only render page that matches id */}
				{pages.map((page) =>
					page.id === currentPageID ? (
						<div key={page.title} className="w-full">
							{page.page}
						</div>
					) : null
				)}
			</div>
		</div>
	);
};

export default EvaluatorDashboard;